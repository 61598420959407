import React from "react";
import { useState, useEffect, useDeferredValue } from "react";
import Link from "next/link";
import { fetchAllCategories, fetchAllCountries } from "@/api/search";
import { useRouter } from "next/router";
import { useUserLocation } from "../context/UserLocationContext";

interface Category {
  id: number;
  categorie_name: string;
}

interface Country {
  i: number;
  s: string;
  n: string;
}

interface Props {
  categories: Category[];
  countries: Country[];
}

const CatagoryCountrySearch: React.FC<Props> = ({ categories, countries }) => {
  //catabgory
  const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
  const [activeLetter, setActiveLetter] = useState<string>("None");
  const [searchTerm, setSearchTerm] = useState<string>("");

  // Use deferred value for searchTerm
  const deferredSearchTerm = useDeferredValue(searchTerm);

  useEffect(() => {
    setFilteredCategories(categories); // Initialize filtered categories with all categories
  }, [categories]);

  // Effect to filter categories based on the deferred search term
  useEffect(() => {
    if (deferredSearchTerm === "") {
      setFilteredCategories(categories); // Reset to all categories if search term is empty
    } else {
      const filtered = categories.filter((category) =>
        category.categorie_name
          .toLowerCase()
          .includes(deferredSearchTerm.toLowerCase())
      );
      setFilteredCategories(filtered);
    }
  }, [deferredSearchTerm, categories]);

  const handleFilter = (letter: string) => {
    setActiveLetter(letter);
    if (letter === "None") {
      setFilteredCategories(categories);
    } else {
      const filtered = categories.filter((category) =>
        category.categorie_name.toLowerCase().startsWith(letter.toLowerCase())
      );
      setFilteredCategories(filtered);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleClear = () => {
    setSearchTerm("");
  };

  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  const excategories = [
    "Clothing stores",
    "Manufacturers",
    "Real estates",
    "Restaurants",
    "Schools",
  ];

  //country
  const [search, setSearch] = useState<string>("");
  const [filteredCountries, setFilteredCountries] = useState<Country[]>([]);
  const userLocation = useUserLocation();
  const router = useRouter();

  useEffect(() => {
    const searchText = search.trim().toLowerCase();
    let query = searchText;
    if (searchText.startsWith("b2b leads in")) {
      query = searchText.substring("b2b leads in".length);
    } else if (searchText.startsWith("b2b leads")) {
      query = searchText.substring("b2b leads".length);
    } else if (searchText.startsWith("leads in")) {
      query = searchText.substring("leads in".length);
    } else if (searchText.startsWith("b2b")) {
      query = searchText.substring("b2b".length);
    } else if (searchText.startsWith("lead")) {
      query = searchText.substring("lead".length);
    }

    const filtered = countries.filter((country) =>
      country.n.toLowerCase().includes(query.trim())
    );

    // Ensure userCountry is at the top of the list
    const sortedFiltered = filtered.sort((a, b) => {
      if (a.n.toLowerCase() === userLocation?.country_name.toLowerCase())
        return -1;
      if (b.n.toLowerCase() === userLocation?.country_name.toLowerCase())
        return 1;
      return 0;
    });

    setFilteredCountries(sortedFiltered);
  }, [search, countries, userLocation]);

  const handleCountryClick = (countrySlug: string) => {
    router.push(`/b2b-list/${countrySlug}-database`);
  };

  const Location = [
    "United Kingdom",
    "United States",
    "Germany",
    "Australia",
    "Canada",
    "India",
  ];

  const [loading, setLoading] = useState(true);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    // Simulate loading or check if data is loaded
    const timer = setTimeout(() => {
      setLoading(false);
      if (!filteredCountries || filteredCountries.length === 0) {
        setNoResults(true);
      } else {
        setNoResults(false);
      }
    }, 1000); // Adjust the delay as needed

    return () => clearTimeout(timer); // Clean up the timer
  }, [filteredCountries]);

  const [noResult, setNoResult] = useState(false);

  useEffect(() => {
    // Simulate loading or check if data is loaded
    const timer = setTimeout(() => {
      setLoading(false);
      if (!filteredCategories || filteredCategories.length === 0) {
        setNoResult(true);
      } else {
        setNoResult(false);
      }
    }, 1000); // Adjust the delay as needed

    return () => clearTimeout(timer); // Clean up the timer
  }, [filteredCategories]);

  return (
    <>
      {/*search section2 country*/}
      <section className="mt-16 mb-16">
        <div className="max-w-screen-xl mx-auto p-8">
          <div className="flex flex-col justify-center mb-8">
            <h2 className="tracking-wide text-[16px] uppercase text-[var(--text-color)] font-semibold mb-2">
              <span className="gradient-new-text">B2B Regional Data</span>
            </h2>
            <h3 className="text-2xl lg:text-4xl font-semibold mb-2 tracking-wide text-[var(--title-color)]">
              Location-Based <span className="gradient-new-text">Datasets</span>
            </h3>
            <div className="text-[var(--text-color)] text-[16px] lg:text-[16px] leading-relaxed">
              <p>
                Browse comprehensive data options, from national trends to local
                insights, tailored to meet your specific business needs.
              </p>
            </div>
          </div>

          <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 text-[16px] text-[var(--text-color)] lg:text-[18px] mb-8">
            <h4 className="flex items-center justify-start w-full max-w-full">
              <svg
                className="w-4 h-4 mr-2 text-[#0070f0]"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              200+ Countries Covered
            </h4>
            <h4 className="flex items-center lg:justify-center md:justify-start w-full max-w-full">
              <svg
                className="w-4 h-4 mr-2 text-[#0070f0]"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              Up-to-date Records
            </h4>
            <h4 className="flex items-center lg:justify-end md:justify-start w-full max-w-full">
              <svg
                className="w-4 h-4 mr-2 text-[#0070f0]"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              Download Sample Data
            </h4>
          </ul>

          <div className="flex flex-col md:flex-row md:space-y-0 md:space-x-3 shadow-lg rounded-lg p-4 bg-white bg-opacity-50">
            <div className="rounded-md text-[var(--title-color)] p-6 max-w-md md:mx-auto md:mb-8 mb-4  -my-5">
              <div className="text-lg font-bold mb-2">Country</div>
              <hr className="border-t-2 border-slate-300 mb-2" />
              <div className="relative w-full md:mt-12 mt-4">
                <ul className="flex overflow-x-auto   md:flex-col md:w-[140px] whitespace-nowrap md:space-y-2 space-x-2 scroll-smooth scrollbar-hide snap-x snap-mandatory md:overflow-x-visible md:whitespace-normal md:space-x-0">
                  {Location.map((location) => (
                    <li
                      key={location}
                      className="flex-shrink-0 items-center hover:underline underline-offset-2 cursor-pointer snap-start md:flex-shrink-0 md:underline-none"
                    >
                      <Link
                        href={`/b2b-list/${location
                          .toLowerCase()
                          .replace(/\s+/g, "-")}-database`}
                      >
                        <ul>
                          <li>{location}</li>
                        </ul>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="flex flex-col w-full">
              {/* Search Input */}
              <div className="flex items-center px-3 mx-auto justify-center w-full space-x-6 bg-transparent rounded-xl border-[2px] md:mb-4 sm:mb-0">
                <div className="flex p-2 space-x-4 font-semibold rounded-lg flex-grow relative">
                  <input
                    autoComplete="off"
                    type="text"
                    id={`search-${
                      userLocation ? userLocation.country_code : "default"
                    }`}
                    placeholder={`Search for datasets based on location${
                      userLocation
                        ? ` (e.g. ${userLocation.country_name})`
                        : ""
                    }`}
                    className="text-sm outline-none text-[var(--text-color)] w-full bg-transparent pr-8"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />

                  {search && (
                    <button
                      className="absolute right-2 top-1/2 transform -translate-y-1/2 text-slate-400"
                      onClick={() => setSearch("")}
                      aria-label="Clear Search"
                    >
                      <svg
                        className="w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 6l8 8m0-8l-8 8"
                        />
                      </svg>
                    </button>
                  )}
                </div>
                <div className="pl-1 sm:pl-10">
                  <button
                    className="w-8 h-8 sm:w-8 sm:h-8 flex items-center justify-center text-slate-400 font-semibold rounded-full cursor-pointer"
                    aria-label="Search"
                  >
                    <svg
                      className="w-4 h-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              {/* Filtered List */}
              <div className="w-full h-[340px] overflow-y-auto p-5 rounded-lg md:mt-4">
                {loading ? (
                  <div className="flex items-center justify-center w-full h-full">
                    <div className="w-16 h-16">
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#0070f0]"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </div>
                  </div>
                ) : noResults ? (
                  <div className="flex items-center justify-center w-full h-full text-gray-500">
                    <p>No Records Found</p>
                  </div>
                ) : (
                  <div className="grid md:grid-cols-2 gap-2 mt-1">
                    {filteredCountries.map((country) => (
                      <div
                        key={country.i}
                        className="w-full h-auto hover:underline underline-offset-4 cursor-pointer text-left flex flex-col bg-transparent text-[var(--title-color)]"
                        // onClick={() => handleCountryClick(country.s)}
                      >
                        <Link href={`/b2b-list/${country.s}-database`}>
                          <div className="pr-5">
                            <ul>
                              <li>B2B Leads in {country.n}</li>
                            </ul>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*search section1 catagory*/}
      <section className="mt-16 mb-24">
        <div className="max-w-screen-xl mx-auto p-8">
          <div className="flex flex-col  justify-center mb-8">
            <h2 className="tracking-wide text-[16px] uppercase text-[var(--text-color)] font-semibold mb-2">
              <span className="gradient-new-text">B2B Category Data</span>
            </h2>
            <h3 className=" tracking-wide text-2xl lg:text-4xl font-semibold mb-2 text-[var(--title-color)]">
              Readily-Available{" "}
              <span className="gradient-new-text">Business Datasets</span>
            </h3>
            <div className="text-[var(--text-color)] text-[16px] lg:text-[16px] leading-relaxed ">
              <p>
                Use BizDataset&apos;s one-stop B2B marketplace to find the
                perfect data for your needs. Explore our diverse categories and
                find what you are looking for.
              </p>
            </div>
          </div>

          <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 text-[16px] text-[var(--text-color)] lg:text-[18px] mb-8">
            <h4 className="flex items-center justify-start w-full max-w-full">
              <svg
                className="w-4 h-4 mr-2 text-[#0070f0]"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              5000+ Categories Covered
            </h4>
            <h4 className="flex items-center lg:justify-center md:justify-start w-full max-w-full">
              <svg
                className="w-4 h-4 mr-2 text-[#0070f0]"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              Free Sample Datasets
            </h4>
            <h4 className="flex items-center lg:justify-end md:justify-start w-full max-w-full">
              <svg
                className="w-4 h-4 mr-2 text-[#0070f0]"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              Data In CSV/JSON
            </h4>
          </ul>

          <div className="flex flex-col md:flex-row  md:space-y-0 md:space-x-1 shadow-xl rounded-lg p-4 bg-white bg-opacity-50">
            <div className="rounded-md text-[var(--title-color)] p-6 max-w-md md:mx-auto md:mb-8 mb-4 md:my-3 ">
              <div className="text-lg font-bold mb-2">Categories</div>
              <hr className="border-t-2 border-slate-300 mb-2 w-auto" />
              <div className="relative w-full md:mt-8 mt-2">
                <ul className="flex overflow-x-auto   md:flex-col md:w-[140px] whitespace-nowrap md:space-y-2 space-x-2 scroll-smooth scrollbar-hide snap-x snap-mandatory md:overflow-x-visible md:whitespace-normal md:space-x-0">
                  {excategories.map((excategorie) => (
                    <li
                      key={excategorie}
                      className="flex-shrink-0 hover:underline underline-offset-2 cursor-pointer snap-start md:flex-shrink md:underline-none"
                    >
                      <Link
                        href={`/b2b-database/list-of-${excategorie
                          .toLowerCase()
                          .replace(/\s+/g, "-")}${
                              userLocation?.country_name
                                ? `-in-${userLocation.country_name
                                    .toLowerCase()
                                    .replace(/\s+/g, "-")}`
                                : "-in-united-states"
                            }`}
                      >
                        {excategorie}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="flex lg:flex-col w-full md:flex-col sm:flex-row">
              <div className="flex items-center justify-start lg:mt-0 mt-20">
                <div className="alphabet flex flex-wrap justify-start mb-2 space-x-1 px-2 text-gray-800 text-base overflow-x-auto flex-col md:flex-row md:space-x-3 space-y-2 md:space-y-0">
                  <span
                    className={`cursor-pointer whitespace-nowrap ${
                      activeLetter === "None" ? "font-bold" : "text-gray-600"
                    }`}
                    onClick={() => handleFilter("None")}
                  >
                    All
                  </span>
                  {alphabet.map((letter) => (
                    <span
                      key={letter}
                      className={`cursor-pointer hover:underline underline-offset-1 whitespace-nowrap ${
                        activeLetter === letter
                          ? "font-bold text-blue-600"
                          : "text-gray-800"
                      }`}
                      onClick={() => handleFilter(letter)}
                    >
                      {letter}
                    </span>
                  ))}
                </div>
              </div>

              <div>
                <div className="flex items-center px-3 mx-auto -ml-9 md:-ml-0 justify-center md:w-full max-w-full space-x-6 bg-transparent rounded-xl border-[2px] mb-4 sm:mb-0">
                  <div className="flex p-2 space-x-4 font-semibold rounded-lg flex-grow align-middle">
                    <input
                      type="text"
                      id="search"
                      autoComplete="off"
                      placeholder="Search for datasets based on category"
                      className="text-sm outline-none text-[var(--text-color)] w-full bg-transparent"
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                    {searchTerm && (
                      <button
                        aria-label="search"
                        onClick={handleClear}
                        className="text-slate-400 font-semibold rounded-full cursor-pointer"
                      >
                        <svg
                          className="w-4 h-4"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                  <div className="pl-0 sm:pl-10">
                    <button
                      className="w-8 h-8 sm:w-8 sm:h-8 flex items-center justify-center text-slate-400 font-semibold rounded-full cursor-pointer"
                      aria-label="Clear Search" // Add an accessible name here
                    >
                      <svg
                        className="w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <div className="md:w-full w-[260px] md:h-[340px] h-[890px] overflow-y-auto p-5 rounded-lg mt-4">
                  {loading ? (
                    <div className="flex items-center justify-center w-full h-full">
                      <div className="w-16 h-16">
                        <svg
                          aria-hidden="true"
                          className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#0070f0]"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                      </div>
                    </div>
                  ) : noResult ? (
                    <div className="flex items-center justify-center w-full h-full text-gray-500">
                      <p>No Records Found</p>
                    </div>
                  ) : (
                    <div className="grid md:grid-cols-2 gap-2 mt-1 w-full overflow-x-auto">
                      {filteredCategories.map((category) => (
                        <div
                          key={category.id}
                          className="w-full h-auto hover:underline underline-offset-8 cursor-pointer text-left flex flex-col bg-transparent text-[var(--title-color)]"
                        >
                          <Link
                            href={`/b2b-database/list-of-${category.categorie_name
                              .toLowerCase()
                              .replace(/\s+/g, "-")}${
                              userLocation?.country_name
                                ? `-in-${userLocation.country_name
                                    .toLowerCase()
                                    .replace(/\s+/g, "-")}`
                                : "-in-united-states"
                            }`}
                          >
                            <div className="pr-5">
                              <ul>
                                <li>
                                  {category.categorie_name} in{" "}
                                  {userLocation?.country_name ||
                                    "United States"}
                                </li>
                              </ul>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CatagoryCountrySearch;
